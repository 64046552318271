/**
 * @file Header.
 */

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/**
 * Header component.
 */
const vHeader = () => {
	const header = document.querySelector('.v-header');

	if (!header) {
		return;
	}

	gsap.registerPlugin(ScrollTrigger);

	const mm = gsap.matchMedia();
	const wrap = header.querySelector('.v-header__wrap');
	const headerLightExists = document.querySelector('.header-light');

	/**
	 * Mobile sticky header animation.
	 * @returns {gsap.core.Timeline} GSAP Animation.
	 */
	const mobileAnimation = () => {
		const height = parseInt(
			getComputedStyle(wrap)
				.getPropertyValue('--header-bottom-height')
				.replace('px', ''),
			10,
		);

		if (headerLightExists) {
			gsap.set('.v-header__logo path', {
				fill: '#201b51',
			});
		}

		const a = gsap
			.timeline({
				defaults: {
					duration: 0.4,
					ease: 'sine.out',
				},
				scrollTrigger: {
					trigger: 'body',
					start: 'top top',
					end: '+=1px',
					toggleActions: 'play none none reverse',
					onRefresh: (st) => {
						if (st.progress === 1 && st.animation.progress() !== 1) {
							st.animation.progress(1);
						}
					},
					onLeaveBack: () => {
						wrap.classList.remove('v-header__wrap--stuck');
						wrap.classList.add('v-header__wrap--stick-transition');
					},
				},
				onStart: () => {
					wrap.classList.add('v-header__wrap--stick-transition');
				},
				onComplete: () => {
					wrap.classList.remove('v-header__wrap--stick-transition');
					wrap.classList.add('v-header__wrap--stuck');
				},
			})
			.to('.v-header__bottom', {
				y: () => 60 - height,
				background: '#fff',
			})
			.to(
				'.v-header__bottom-inner',
				{
					y: () => (height - 60) / 2,
				},
				'<',
			)
			.to(
				'.v-header__logo-link',
				{
					scale: 0.9,
				},
				'<',
			)
			.to(
				'.v-header__logo path',
				{
					fill: '#201b51',
				},
				'<',
			)
			.to(
				wrap,
				{
					'--header-shadow-reveal': 1,
				},
				'<',
			);

		if (header.classList.contains('v-header--overlay')) {
			a.to(
				'.v-header__bottom',
				{
					'--header-link-color': '#222',
					backgroundColor: '#fff',
				},
				'<',
			);
		}

		return a;
	};

	/**
	 * Desktop sticky header animation.
	 * @returns {gsap.core.Timeline} GSAP Animation.
	 */
	const desktopAnimation = () => {
		const height = gsap.getProperty(wrap, '--header-bottom-height');
		const gap =
			gsap.getProperty('.v-header__nav-list', 'column-gap') +
			gsap.getProperty('.v-header__nav-item--icon', 'margin-inline-start');
		const triggerWidth = gsap.getProperty(wrap, '--header-trigger-width');
		const navIcons = document.querySelectorAll('.v-header__nav-icon path');

		if (headerLightExists) {
			gsap.set('.v-header__logo path', {
				fill: '#201b51',
			});
			gsap.set('.v-header__nav-link', {
				color: '#000000',
			});
			gsap.set(navIcons, {
				fill: '#000000',
			});
		}

		const a = gsap
			.timeline({
				defaults: {
					duration: 0.4,
					ease: 'sine.out',
				},
				scrollTrigger: {
					trigger: 'body',
					start: 'top top',
					end: '+=1px',
					toggleActions: 'play none none reverse',
					onRefresh: (st) => {
						if (st.progress === 1 && st.animation.progress() !== 1) {
							st.animation.progress(1);
						}
					},
					onLeaveBack: () => {
						wrap.classList.remove('v-header__wrap--stuck');
						wrap.classList.add('v-header__wrap--stick-transition');
					},
				},
				onStart: () => {
					wrap.classList.add('v-header__wrap--stick-transition');
					gsap.set('.v-header__nav-trigger', {
						display: 'block',
					});
				},
				onComplete: () => {
					wrap.classList.remove('v-header__wrap--stick-transition');
					wrap.classList.add('v-header__wrap--stuck');
				},
				onReverseComplete: () => {
					wrap.classList.remove('v-header__wrap--stick-transition');

					gsap.set(
						a
							.getChildren()
							.map((t) => t.targets())
							.concat(['.v-header__nav-trigger'])
							.flat(),
						{ clearProps: 'all' },
					);
				},
			})
			.to(wrap, {
				y: () =>
					parseInt(
						getComputedStyle(wrap)
							.getPropertyValue('--header-top-height')
							.replace('px', ''),
						10,
					) * -1,
			})
			.to(
				'.v-header__bottom',
				{
					y: () => 60 - height,
					backgroundColor: '#ffffff',
				},
				'<',
			)
			.to(
				'.v-header__bottom-inner',
				{
					y: () => (height - 60) / 2,
				},
				'<',
			)
			.to(
				'.v-header__logo-link',
				{
					scale: 0.87804878,
				},
				'<',
			);
		if (!headerLightExists) {
			a.to(
				'.v-header__logo path',
				{
					fill: '#201b51',
				},
				'<',
			)
				.to(
					navIcons,
					{
						fill: '#000000',
					},
					'<',
				)
				.to(
					'.v-header__nav-link',
					{
						color: '#000000',
					},
					'<',
				);
		}

		a.to(
			wrap,
			{
				'--header-shadow-reveal': 1,
			},
			'<',
		)
			.to(
				'.v-header__nav',
				{
					x: () => (triggerWidth + gap) * -1,
				},
				'<',
			)
			.to(
				'.v-header__nav-trigger-inner',
				{
					'--clip': '0%',
					opacity: 1,
				},
				'<',
			);

		if (header.classList.contains('v-header--overlay')) {
			a.to(
				'.v-header__bottom',
				{
					'--header-link-color': '#222',
					backgroundColor: '#fff',
				},
				'<',
			).to(
				'.v-header__nav-link',
				{
					backgroundColor: 'rgba(0,0,0,var(--header-link-bg-opacity))',
					duration: 0,
				},
				'<+50%',
			);
		}

		return a;
	};

	mm.add('(max-width: 1139px)', () => {
		mobileAnimation();
	})
		.add('(min-width: 1140px) and (max-width: 1229px)', () => {
			desktopAnimation();
		})
		.add('(min-width: 1230px) and (max-width: 1379px)', () => {
			desktopAnimation();
		})
		.add('(min-width: 1380px)', () => {
			desktopAnimation();
		});
};

export default vHeader;
