/**
 * @file Resource Selection.
 */

/**
 * Resource Selection component.
 */
const vResourceSelection = () => {
	const resourceSelection = document.querySelector('.v-resource-selection');

	if (!resourceSelection) {
		return;
	}

	const filterDropDown = document.querySelector(
		'.v-resource-selection__select',
	);

	const resourceElements = document.querySelectorAll(
		'.v-resource-selection__link',
	);
	const resourcesList = [...resourceElements].map((el) => ({
		text: el.text,
		url: el.getAttribute('href'),
		className: 'v-resource-selection__link',
	}));

	filterDropDown.addEventListener('change', (e) => {
		e.preventDefault();
		const searchTerm = e.target.value;
		resourcesList.forEach((resources, index) => {
			resourceElements[index].style.display = 'block';
		});
		resourcesList.forEach((resource, index) => {
			if (searchTerm === '') {
				resourceElements[index].style.display = 'block';
			} else {
				const searchTerms = searchTerm
					.toLowerCase()
					.replace(/\s{2,}/g, ' ')
					.split(' ');
				searchTerms.some((term) => {
					const searchMatched = resource.text.toLowerCase().includes(term);
					if (!searchMatched) {
						resourceElements[index].style.display = 'none';
					}
					return searchMatched;
				});
			}
		});
	});
};

export default vResourceSelection;
