/**
 * @file Single Product.
 */

/**
 * Single Product component.
 */
const singleProduct = () => {
	const singleProductPage = document.querySelector('.v-simple-product');

	if (!singleProductPage) {
		return false;
	}

	const productNotice = document.querySelector(
		'.wc-block-components-notice-banner',
	);

	if (productNotice) {
		setTimeout(() => {
			productNotice.style.display = 'none';
		}, 5000);
	}
};

export default singleProduct;
