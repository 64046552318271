/**
 * @file Trailer Type Selection.
 */
import { gsap } from 'gsap';

/**
 * Trailer Type Selection component.
 */
const vTrailerTypeSelection = () => {
	const accordion = document.querySelectorAll(
		'.v-trailer-type-selection__accordion',
	);
	const allAccordionContent = document.querySelectorAll(
		'.v-trailer-type-selection__accordion-content',
	);
	const allPlusIcons = document.querySelectorAll(
		'.v-trailer-type-selection__plus-icon',
	);
	const allMinusIcons = document.querySelectorAll(
		'.v-trailer-type-selection__minus-icon',
	);

	if (!accordion) {
		return;
	}

	gsap.set(allMinusIcons, {
		autoAlpha: 0,
	});

	gsap.set(allAccordionContent, {
		autoAlpha: 0,
	});

	accordion.forEach((el) => {
		const accordionContent = el.querySelector(
			'.v-trailer-type-selection__accordion-content',
		);
		const plusIcon = el.querySelector('.v-trailer-type-selection__plus-icon');
		const minusIcon = el.querySelector('.v-trailer-type-selection__minus-icon');

		el.addEventListener('click', () => {
			// Check if clicked acc is currently open
			const isOpen = gsap.getProperty(accordionContent, 'display') !== 'none';

			// Close all accordions
			// gsap.set(allAccordionContent, {
			// 	display: 'none',
			// 	autoAlpha: 0,
			// });
			// gsap.set(allMinusIcons, {
			// 	autoAlpha: 0,
			// });
			// gsap.set(allPlusIcons, {
			// 	autoAlpha: 1,
			// });

			// Toggle the clicked accordion based on its current state
			if (!isOpen) {
				// Show clicked accordion if it was closed
				gsap.set(accordionContent, {
					display: 'block',
				});
				gsap.to(accordionContent, {
					autoAlpha: 1,
				});
				gsap.set(plusIcon, {
					autoAlpha: 0,
				});
				gsap.to(minusIcon, {
					autoAlpha: 1,
				});
			} else {
				// Close this accordion
				gsap.set(el.querySelector('.v-trailer-type-selection__accordion-content'), {
					display: 'none',
					autoAlpha: 0,
				});
				gsap.set(minusIcon, {
					autoAlpha: 0,
				});
				gsap.set(plusIcon, {
					autoAlpha: 1,
				});
			}
		});
	});
};

export default vTrailerTypeSelection;
