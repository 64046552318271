const customNumberRange = () => {
	if (document.querySelector('.v-product-content__add-to-cart')) {
		const controls = document.createElement('div');
		controls.classList.add('v-quantity-controls');

		const upBtn = document.createElement('div');
		const downBtn = document.createElement('div');
		upBtn.classList.add(
			'v-quantity-controls__btn',
			'v-quantity-controls__btn--up',
		);
		downBtn.classList.add(
			'v-quantity-controls__btn',
			'v-quantity-controls__btn--down',
		);

		controls.appendChild(upBtn);
		controls.appendChild(downBtn);

		const input = document.querySelector(
			'.v-product-content__add-to-cart input[type="number"]',
		);
		const min = input.getAttribute('min');
		const max = input.getAttribute('max');
		let newVal = 0;

		upBtn.addEventListener('click', () => {
			const oldValue = parseFloat(input.value);

			if (max.length && oldValue >= max) {
				newVal = oldValue;
			} else {
				newVal = oldValue + 1;
			}

			input.value = newVal;
			input.dispatchEvent(new Event('change'));
		});

		downBtn.addEventListener('click', () => {
			const oldValue = parseFloat(input.value);

			if (min.length && oldValue <= min) {
				newVal = oldValue;
			} else {
				newVal = oldValue - 1;
			}

			input.value = newVal;
			input.dispatchEvent(new Event('change'));
		});

		input.insertAdjacentElement('afterend', controls);
	}
};
export default customNumberRange;
