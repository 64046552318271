/**
 * @file Checkmark List.
 */

import { gsap, ScrollTrigger } from 'gsap';

gsap.registerPlugin(ScrollTrigger);

/**
 * Full Screen Bg Text component.
 */
const vCheckmarkList = () => {
	const checkMarkList = document.querySelectorAll('.v-checkmark-list');
	const checkMarkListAnimate = document.querySelectorAll(
		'.v-checkmark-list__animate',
	);

	if (!checkMarkList) {
		return;
	}

	const mm = gsap.matchMedia();

	checkMarkListAnimate.forEach((element) => {
		const elContent = element.querySelector('.v-checkmark-list__content');
		const elLeftBar = element.querySelector('.v-checkmark-list__anim-bar-left');
		const elRightBar = element.querySelector(
			'.v-checkmark-list__anim-bar-right',
		);

		mm.add('(max-width: 1199px)', () => {
			gsap.set([elLeftBar, elRightBar], {
				autoAlpha: 0,
				display: 'none',
			});
			gsap.set(elContent, {
				y: 0,
			});
		});

		mm.add('(min-width: 1200px)', () => {
			gsap.set([elLeftBar, elRightBar], {
				display: 'block',
				autoAlpha: 1,
			});
			gsap.to(elLeftBar, {
				scrollTrigger: {
					trigger: element,
					start: 'top center',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
				x: '-100%',
			});
			gsap.to(elRightBar, {
				scrollTrigger: {
					trigger: element,
					start: 'top center',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
				x: '100%',
			});
			gsap.from(elContent, {
				y: 200,
				scrollTrigger: {
					trigger: elContent,
					start: 'top bottom',
					end: 'center center',
					toggleActions: 'play reverse play reverse',
					scrub: true,
				},
			});
		});
	});
};

export default vCheckmarkList;
