/**
 * @file Anything that can run on every page.
 */
// test3
import vHeader from '../components/header/header';
import vOffCanvasNav from '../components/off-canvas-nav/off-canvas-nav';
import vSearchForm from '../components/search-form/search-form';
import vWeightCalculator from '../components/boat-weight-calculator-form/boat-weight-calculcator';
import vFullScreenBgText from '../components/full-screen-bg-text/full-screen-bg-text';
import vVideo from '../components/video/video';
import vTrailerTypeSelection from '../components/trailer-type-selection/trailer-type-selection';
import vCardLink from '../components/card-link/card-link';
import Account from '../components/my-account/my-account';
import vSingleResourceAccordion from '../components/single-resource-accordion/single-resource-accordion';
import vCheckmarkList from '../components/checkmark-list/checkmark-list';
import vGravityForms from '../components/form/v-gravity-form';
import vFindADealer from './find-a-dealer';
import vSingleProduct from '../templates/single-product/single-product';
import vCustomNumberRange from '../components/custom-number-range/custom-number-range';
import vResourceSelection from '../components/resource-selection/resource-selection';
import customFont from "./custom-font";

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vGlobal = () => {
	vOffCanvasNav();
	vHeader();
	vSearchForm();
	vWeightCalculator();
	vFullScreenBgText();
	vVideo();
	vTrailerTypeSelection();
	vCardLink();
	vSingleResourceAccordion();
	vCheckmarkList();
	vGravityForms();
	vSingleProduct();
	vCustomNumberRange();
	vResourceSelection();
	customFont();
	Account.init();
	vFindADealer.init();

	const floatingLabelWraps = document.querySelectorAll(
		'.v-form__floating-label-wrap',
	);
	[...floatingLabelWraps].forEach((wrap) => {
		const label = wrap.querySelector('.v-form__floating-label');
		const input = wrap.querySelector('input, select');

		input.addEventListener('focus', () => {
			label.classList.add('v-form__floating-label--float');
		});

		input.addEventListener('blur', () => {
			if (input.value === '') {
				label.classList.remove('v-form__floating-label--float');
			}
		});
	});

	const scrollBarWidth =
		window.innerWidth - document.documentElement.clientWidth;
	document.documentElement.style.setProperty(
		'--scroll-bar-width',
		`${scrollBarWidth}px`,
	);

	/**
	 * Global Window Load.
	 *
	 * Runs polyfills on page load.
	 */
	async function onWindowLoad() {
		const body = document.querySelector('body');
		if (!body.classList.contains('admin-bar')) {
			// const { listen } = await import('quicklink');
			// listen({
			// 	ignores: [
			// 		(url) => {
			// 			const cleanUrl = url.replace(/#.*/, '');
			// 			return cleanUrl === window.location.href;
			// 		},
			// 		/.*\/wp-admin\/.*/,
			// 	],
			// });
		}
	}

	if (document.readyState === 'complete') {
		onWindowLoad();
	} else {
		window.addEventListener('load', onWindowLoad);
	}
};

if (document.readyState !== 'loading') {
	vGlobal();
} else {
	document.addEventListener('DOMContentLoaded', () => {
		vGlobal();
		vHeader();
		vOffCanvasNav();
	});
}
